<template>
  <el-container
    class="selection-card flex-column cursor-pointer"
    :class="{'isActive': selectedValue === data.value, 'minWidthUnset': unsetMinWidth}">
    <section v-if="hasTag">
      <slot name="tag"></slot>
    </section>
    <section class="selection-card__wrapper flex-row ai-fs">
      <Icon
        v-if="data.icon"
        iconHeight="50px"
        iconWidth="50px"
        :iconName="data?.icon"
        xsMobileSize="30"/>
      <div class="flex-column gap-half">
        <Typography
          variant="h4"
          xsMobileFont="20"
          :textColor="selectedValue === data.value ? '#FFF' : defaultColor">
          {{ data?.title }}
        </Typography>
        <Typography
          variant="p" textWeight="500"
          xsMobileFont="14"
          :textColor="selectedValue === data.value ? lightWhite : grayColor">
          {{ data?.label }}
        </Typography>
      </div>
    </section>
  </el-container>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography
  },

  props: ['data', 'hasTag', 'selectedValue', 'unsetMinWidth'],

  computed: {
    grayColor() {
      return 'rgba(12, 15, 74, 0.50)';
    },

    accentColor() {
      return '#FAA500';
    },

    defaultColor() {
      return '#0C0F4A';
    },

    lightWhite() {
      return 'rgba(255, 255, 255, 0.5)';
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.selection-card {
  box-shadow: 0px 0px 10px 0px rgba(12, 15, 74, 0.20);
  background: #FFF;
  border-radius: 20px;
  min-width: 683px;
  min-height: 90px;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
}

.selection-card.minWidthUnset {
  min-width: unset;
}

.selection-card.isActive {
  background: #3639AF;
}

@include media-sm-max-width() {
  .selection-card {
    min-width: 300px;
  }
}

@include media-xs-max-width() {
  .selection-card {
    &__wrapper {
      align-items: flex-start;
    }
  }
}
</style>
