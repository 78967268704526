<template>
  <div class="tag">
    <Typography
      variant="p"
      textWeight="700" style="margin-right: .5rem !important;"
      :textColor="selectedValue === dataValue ? '#FFF' : defaultColor">
        {{ tag }}
    </Typography>
    <Typography
      variant="p"
      textWeight="500"
      :textColor="selectedValue === dataValue ? '#FFF' : defaultColor">
        {{ tagLabel }}
    </Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography
  },

  props: ['tag', 'tagLabel', 'selectedValue', 'dataValue'],

  computed: {
    grayColor() {
      return 'rgba(12, 15, 74, 0.50)';
    },

    accentColor() {
      return '#FAA500';
    },

    defaultColor() {
      return '#0C0F4A';
    },

    lightWhite() {
      return 'rgba(255, 255, 255, 0.5)';
    },
  },

  methods: {
  },
});
</script>
<style lang="scss" scoped>
.tag {
    display: inline-block;
    border: 1px solid #FAA500;
    padding: .3rem 1rem;
    border-radius: 6px;
    box-sizing: border-box;
}
</style>
