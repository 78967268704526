<template>
  <el-container class="account-role flex-column ai-c jc-c">
    <div class="account-role__body flex-column gap-2">
      <section class="flex-column gap-2">
        <Icon iconName="iknowa-logo-v4.svg" iconHeight="36px" iconWidth="198px"/>
        <div class="flex-column">
          <Typography variant="h4">
            Welcome, {{ firstName }} 👋
          </Typography>
          <div class="flex-column gap-half">
            <Typography variant="p" textWeight="500" :textColor="grayColor">
              Let’s get you started on Iknowa.
            </Typography>
            <div class="account-role__wrapper flex-row ai-c gap-half">
              <div class="flex-row ai-c gap-half">
                <Typography variant="p" textWeight="500" :textColor="grayColor" whiteSpace="nowrap">
                  Please select the
                </Typography>
                <Typography variant="p" textWeight="700" whiteSpace="nowrap">
                  account type
                </Typography>
              </div>
              <Typography variant="p" textWeight="500" :textColor="grayColor" whiteSpace="nowrap">
                that best describes you.
              </Typography>
            </div>
          </div>
        </div>
      </section>
      <section class="account-role__content flex-column jc-sb">
        <div class="flex-column">
          <SelectionCard
            @click="selectCard(role.value)"
            :hasTag="true"
            v-for="(role, index) in roles"
            :key="index"
            :data="role"
            :selectedValue="selectedRole">
            <template #tag>
              <Tag
                :tag="role.tag"
                :tagLabel="role.tagLabel"
                :dataValue="role.value"
                :selectedValue="selectedRole"/>
            </template>
          </SelectionCard>
        </div>
        <Button
          @click="submit"
          :isActive="hasSelectedRole"
          :loading="isSubmitting"
          buttonText="next"/>
      </section>
    </div>
  </el-container>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import SelectionCard from '@/core/components/common/cards/SelectionCard.vue';
import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Tag from '@/core/components/ui/Tag.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { AUTH_SESSION_EXPIRY, AUTH_USER } from '@/core/constants';
import StringHelper from '@/core/helpers/string.helper';
import { RolesEnum } from '@/core/packages/shared-library';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/core/utils/common';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

import { DASHBOARD_ROUTE } from '../../dashboard/routes';

export default defineComponent({
  components: {
    Button,
    Icon,
    SelectionCard,
    Typography,
    Tag
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(PROJECTS_STORE, ['currentPageViewMapping']),

    grayColor() {
      return 'rgba(12, 15, 74, 0.50)';
    },

    accentColor() {
      return '#FAA500';
    },

    hasSelectedRole() {
      return this.selectedRole || this.selectedRole !== null;
    },

    firstName() {
      return this.user?.firstName.charAt(0).toUpperCase() + this.user?.firstName.slice(1);
    }
  },

  data() {
    return {
      roles: [
        {
          value: RolesEnum.PROJECT_OWNER,
          title: 'Manage your property',
          label: 'Get Your Free ‘Know Your Property’ insight dashboard and start your first Iknowa project.',
          icon: 'icon-house.svg',
          tag: 'FREE',
          tagLabel: 'KYP Property Dashboard'
        },
        {
          value: RolesEnum.TRADESPERSON,
          title: 'Join As a Trade Contractor',
          label: 'Create Your Free Iknowa Trade Passport. For Sole Traders and Limited Companies.',
          icon: 'icon-tp-avatar.svg',
          tag: '0',
          tagLabel: 'Live Projects To Bid On'
        }
      ],

      selectedRole: null,
      isSubmitting: false,
      StringHelper,

      isLoadingProjects: false,
    };
  },

  methods: {
    ...mapActions(USERS_STORE, [
      'selectRole',
      'updateUser',
      'saveUserState',
      'getUser'
    ]),

    ...mapActions(PROJECTS_STORE, ['getProjectPageList']),

    selectCard(value) {
      this.selectedRole = value;
    },

    async submit() {
      const { user, selectedRole } = this;

      this.isSubmitting = true;

      await this.selectRole({
        userId: user.id,
        roleId: selectedRole,
        email: user.email
      }).then(async (response) => {
        const authUser = getLocalStorageWithExpiry(AUTH_USER);
        authUser.userId = response.id;
        authUser.roleId = response.role.id;
        authUser.hasChosenRole = response.hasChosenRole;
        authUser.aliased = StringHelper.isAliased(response.email);
        setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);

        this.initializing = true;
        await this.saveUserState(response);

        window.location.href = `/${DASHBOARD_ROUTE}`;
      }).catch(() => {
        this.$notify.error({
          title: 'Error',
          message: 'A problem occured. Please try again.'
        });
      }).finally(() => {
        this.isSubmitting = false;
      });
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;
.account-role {
  width: 100%;
  height: 100%;
  background: transparent;
  overflow-y: auto;

  &__body {
    max-width: 683px;
    min-height: 600px;
  }

  &__content {
    height: 100%;
  }
}

@include media-sm-max-width() {
  .account-role {
    padding: 1rem 1.5rem;
    justify-content: flex-start;

    &__body {
      min-height: unset;
      height: 100%;
    }
  }
}

@media screen and (max-width: 382px) {
  .account-role {
    &__wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
